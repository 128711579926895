/* Inspired from https://projects.verou.me/bubbly/  */

.bubble {
  @apply flex flex-col;
  filter: drop-shadow(0 0 8px rgb(0 0 0 / 0.12)) drop-shadow(0 0 3px rgb(0 0 0 / 0.3));
  @apply relative bg-white p-6 rounded-lg text-base mx-4 sm:mx-8;
  @apply max-w-md md:max-w-xs lg:max-w-sm xl:max-w-md;
  @apply after:content-[''] after:absolute after:bottom-[1rem] after:left-0 after:w-0 after:h-0;
  @apply after:border-transparent after:border-r-white after:border-solid after:border-[1rem] after:border-b-0 after:border-l-0;
  @apply after:mt-[-0.5rem] after:ml-[-1rem];
}

.bubble .content {
  @apply overflow-y-hidden;
}
