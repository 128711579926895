.status {
  @apply text-2xl inline-block drop-shadow-sm;
}

.status.passed {
  @apply text-success;
}

.status.completed {
  @apply text-error;
}

.status.incomplete {
  @apply text-info;
}

.status.open {
  @apply text-gray-500;
}

.status.missing {
  @apply text-warning;
}
