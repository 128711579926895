.date-input {
  @apply w-40;
}

.time-input {
  @apply w-24;
}

.centered-popup {
  @apply m-auto !top-0 right-0 bottom-0 !left-0 w-fit h-fit;
}
