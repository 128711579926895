.container {
  @apply flex flex-row justify-center;
}

.input {
  @apply text-2xl text-primary tracking-code font-mono;
}

.title {
  @apply text-center;
}

.icon {
  @apply text-4xl text-center text-gray-400 mb-4;
}