.card {
  @apply flex flex-col gap-8;
}

.content {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6;
}

.actions {
  @apply justify-self-end self-end;
  @apply flex flex-row gap-2;
  @apply print:hidden;
}

.meta {
  @apply flex flex-col gap-3;
}

.date {
  @apply text-gray-400 font-light text-sm;
}

.flow {
  @apply text-gray-400 font-light sm:text-right lg:text-left;
}

.expires {
  @apply text-error font-light text-sm sm:text-right lg:text-left;
}

.process {
  @apply flex flex-col gap-3;
  @apply sm:items-end lg:items-start;
}

.ref {
  @apply flex flex-col gap-3;
}

.ref .link {
  @apply flex flex-row gap-3 items-center;
}

.ref .code {
  @apply text-primary font-mono tracking-wider;
}

.ref .copy {
  @apply print:hidden;
}
