.text {
  @apply text-base mb-2;
}

.text table {
  @apply text-base mb-6 w-full;
  @apply table-auto;
}

.text th {
  @apply border-b border-gray-200;
  @apply font-medium;
}

.text th,
.text td {
  @apply py-2 px-2;
  @apply border-x border-gray-200;
  @apply align-top;
}

.text th:first-child,
.text td:first-child {
  @apply pl-0;
  @apply border-l-0;
}

.text th:last-child,
.text td:last-child {
  @apply pr-0;
  @apply border-r-0;
}
