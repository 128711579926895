.container {
  @apply p-6 pb-2 text-center min-h-full flex flex-col;
}

.heading {
  @apply my-0 py-12;
}

.logo {
  @apply font-heading font-medium text-6xl;
}

.slogan {
  @apply text-xl grow;
}

.legal-nav {
  @apply flex flex-col xs:flex-row justify-center items-center;
}