.content {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4;
  @apply items-center;
}

.title {
  @apply font-medium;
}

.info {
  @apply font-light text-left sm:text-right lg:text-left;
}

.status {
  @apply lg:justify-self-center;
}

.actions {
  @apply justify-self-end self-end;
  @apply flex flex-row gap-2;
}
