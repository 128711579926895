.control {
  @apply relative;
}

.control .button {
  @apply h-16 w-16 shadow-lg text-2xl;
}

.control .pulse {
  @apply absolute top-0 left-0 h-full w-full bg-primary opacity-75;
  @apply rounded-[50%];
  @apply animate-ping z-0;
}
