.heading {
  @apply text-gray-950 font-medium;
}

h1.heading {
  @apply text-4xl mb-10;
}

h2.heading {
  @apply text-3xl mb-8;
}

h3.heading {
  @apply text-2xl mb-6;
}

h4.heading {
  @apply text-xl mb-4;
}

h5.heading {
  @apply text-lg mb-2;
}

h6.heading {
  @apply text-base mb-2;
}
