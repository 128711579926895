.input {
  @apply max-w-xs grow;
}

.radio {
  @apply text-base;
}

.radio span {
  /* align the radio button at start for long labels */
  @apply !self-start !mt-[3px];
}

.radio.error span {
  @apply border-error;
}

.radio.warning span {
  @apply border-warning;
}
