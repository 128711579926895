.title {
  @apply text-base;
}

.description {
  @apply text-sm;
}

.notification {
  @apply !p-4;
}

div:has(.notification) {
  @apply !mb-0;
}
