.actions {
  @apply flex justify-end gap-2;
}

.optional {
  @apply max-lg:hidden;
}

div.table-actions {
  @apply flex justify-end gap-2;
  @apply mb-4;
}

.pending {
  @apply text-gray-400 font-light;
}

.primary {
  @apply font-medium;
}

.secondary {
  @apply font-light text-sm;
}
