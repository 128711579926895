.layout {
  @apply flex flex-col gap-8;
}

.control {
  @apply flex flex-row justify-start items-center gap-4;
}

.control .label {
  @apply text-ellipsis overflow-hidden;
}

.sharing {
  @apply flex flex-col items-center gap-4;
}
