.consultation-sider .tab-label {
  @apply pl-3 sm:pr-3;
}

.consultation-sider .ant-tabs-content-holder {
  @apply overflow-y-auto p-6;
}

.consultation-sider .ant-tabs-nav {
  @apply mb-0;
}

.consultation-sider .ant-tabs-tab {
  @apply py-4
}

/* .content-wrapper {
  @apply h-full overflow-y-auto;
} */
