#app .header-navigation {
  @apply flex justify-between h-full;
}

#app .header-navigation .title {
  @apply shrink text-xl sm:text-2xl text-white m-0 font-medium truncate;
  /* @apply hidden sm:block; */
}

#app .header-navigation .logo {
  @apply shrink-0;
  @apply pr-2 pb-4 pt-3.5;
}

#app .header-navigation .cta {
  @apply print:hidden;
}

#app .header-navigation .cta button {
  /* makes the button icons centrally aligned */
  @apply inline-flex justify-center items-center;
}
