.basic-layout {
  /* compensate padding on app div container */
  @apply -m-6;
}

.basic-layout .header {
  @apply fixed w-full z-10 px-6;
  @apply print:absolute;
}

.basic-layout .sider {
  @apply fixed h-full pt-16 top-0 right-0 shadow-lg;
  @apply print:hidden;
}

.basic-layout .sider .sider-container {
  @apply h-full flex flex-col justify-between;
}

.basic-layout .sider .sider-container .content {
  @apply h-0 grow overflow-y-auto;
}

.basic-layout .sider-collapse-btn {
  @apply fixed h-16 w-16 right-4 bg-white/70 shadow-lg text-2xl;
  line-height: 0;
  @apply print:hidden;
}

.basic-layout .footer {
  @apply flex flex-row justify-center items-center gap-1;
}

.no-overflow {
  @apply overflow-hidden print:overflow-auto;
}

.blur {
  @apply blur-sm print:blur-none;
}

.basic-layout .main {
  @apply print:!h-fit print:!mr-0;
  @apply overflow-x-hidden;
}
