.wrapper {
  @apply w-[90vw] xs:w-[80vw] sm:w-[460px];
}

.card {
  @apply relative;
}

.close {
  /* @apply opacity-40 hover:opacity-90; */
}

.container {
  @apply flex flex-col gap-6;
}

.header {
  @apply flex flex-row gap-2 justify-between items-center;
}

.header .status {
  @apply text-primary font-medium;
}

.content {
  /* @apply bg-orange-200; */
}

.footer {
  @apply flex flex-row gap-2 justify-end;
}

.controls {
  @apply flex flex-row gap-2 justify-end;
}

.controls .icon {
  font-size: 1.25rem !important;
}

.controls .back,
.controls .next {
  /* @apply text-gray-300; */
}
