div.drug {
  @apply mb-6;
}

div.header {
  @apply lg:col-span-2 xxl:col-span-3;
}

div.comparison {
  @apply text-sm font-normal grid gap-2 grid-cols-1 lg:grid-cols-2;
}

div.pros {
  @apply bg-primary/30 p-3 rounded;
  @apply break-inside-avoid;
}

.pros ul {
  @apply list-['+\0020'] pl-3 list-outside;
}

div.cons {
  @apply bg-error/30 p-3 rounded;
  @apply break-inside-avoid;
}

.cons ul {
  @apply list-['–\0020'] pl-3 list-outside;
}

div.interactions {
  @apply bg-gray-100 p-3 rounded lg:col-span-2;
  @apply break-inside-avoid;
}

.interactions ul.products {
  @apply list-none pl-0 list-outside;
}

.interactions ul.products li {
  @apply mt-4;
}

.product {
  @apply flex flex-row gap-2;
}

.interactions ul.product-interactions {
  @apply list-none pl-6 list-outside;
}

.interactions ul.product-interactions li {
  @apply mt-2 text-sm;
  @apply flex flex-row gap-2;
}

.comparison li {
  @apply mb-1;
}

.additional-info {
  @apply max-w-[65vw] sm:max-w-[75vw] md:max-w-[70vw] lg:max-w-[60vw] xl:max-w-[50vw];
}
