.content {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-4 mb-10;
}

.actions {
  @apply flex flex-row gap-3 justify-end;
  @apply print:hidden;
}

.qrcode {
  @apply w-full max-w-[8rem] justify-self-start sm:justify-self-end;
}

.flow-id {
  @apply text-gray-400 truncate text-sm;
}
