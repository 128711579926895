.container {
  @apply p-0 sm:p-6;
  @apply print:p-6
}

.container .card {
  @apply bg-white p-6;
  @apply sm:rounded-lg sm:shadow-md;
  @apply max-w-3xl mx-auto;
  @apply print:bg-transparent print:shadow-none print:max-w-none print:p-0;
}
