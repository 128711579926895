.card {
  @apply mb-4;
}

.pagination {
  @apply text-right mt-4;
}

.modal-title {
  @apply flex flex-row gap-4 items-center mb-6 mt-4;
}
