.item {
  @apply border-b py-4;
  @apply grid grid-cols-3 gap-2 items-center;
}

.actions {
  @apply justify-self-end;
  @apply flex flex-row gap-2;

}

.status {
  @apply justify-self-start;
  @apply flex flex-row gap-2;

}
