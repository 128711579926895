.menu {
  @apply -mt-6 -mx-6 sm:rounded-t-lg;
}

.item::after {
  @apply !inset-x-[1px];
}

.label {
  @apply font-medium;
}

.content {
  @apply pt-6;
}
