.status {
  @apply text-2xl inline-block drop-shadow-sm;
}

.status.error {
  @apply text-error;
}

.status.incomplete {
  @apply text-warning;
}

.status.success {
  @apply text-success;
}

.status.open {
  @apply text-gray-500;
}

.status.missing {
  @apply text-warning;
}

.status.ignore {
  @apply text-gray-500;
}