.ref {
  @apply text-primary font-mono tracking-wider;
}

.header {
  @apply flex flex-row gap-4 justify-between items-center mb-8;
}

.print {
  @apply hidden print:inline;
}
